import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  IClean,
  ICleanResponse,
  ICleanUpdate,
  IContractIncident,
  IContractIncidentRequest,
  IExistingReport,
  IGeoJSON,
  IIncidentReport,
  IIncidentRequest,
  IIncidentType,
  ISwimmingPoolGridData,
  ITimelineItem,
  IUData,
  IUserAuth,
  IUserContract,
  IVisitor,
  IVisitReport,
  IVisitReportResponse,
} from '../../tsTypes/interfaces';
import { IDateRange, IResponseData } from '../../tsTypes/types.model';
import { periopsisApi } from '../api/apiSlice';
import { RootState } from '../../app/store';
import { formatDateForInputFormState } from '../../utils/componentUtilFns';
import getHeaders from '../../utils/getHeaders';
import { Coordinates, reportType } from '../../tsTypes/types';
import { IncidentStatus, Status } from '../../tsTypes/enums';

export interface IIncidentState {
  status: string;
  fromDate: string;
  toDate: string;
  selectedIncidentTypeId: string;
}

const today = new Date(new Date().toDateString());
const currentYear = today.getFullYear();
const fromDateValue = new Date(currentYear, 0, 1);
const toDateValue = new Date(currentYear, 11, 31);

const initialState: IIncidentState = {
  status: localStorage.getItem('filterStatus') || '',
  fromDate:
    localStorage.getItem('from-date') ||
    formatDateForInputFormState(fromDateValue),
  toDate:
    localStorage.getItem('to-date') || formatDateForInputFormState(toDateValue),
  selectedIncidentTypeId: '',
};

const incidentSlice = createSlice({
  name: 'incidents',
  initialState,
  reducers: {
    setCurrentFilterStatus: (state, action: PayloadAction<IncidentStatus>) => {
      localStorage.setItem('filterStatus', action.payload);

      state.status = action.payload;
    },
    setFromDate: (state, action: PayloadAction<string>) => {
      state.fromDate = action.payload;
    },
    setToDate: (state, action: PayloadAction<string>) => {
      state.toDate = action.payload;
    },
    setSelectedIncidentType: (state, action: PayloadAction<string>) => {
      localStorage.setItem('selected-incident-type', action.payload);

      state.selectedIncidentTypeId = action.payload;
    },
  },
});

export const extendedApiSlice = periopsisApi.injectEndpoints({
  endpoints: (builder) => ({
    getContracts: builder.query({
      query: () => {
        const headers = getHeaders();

        return {
          url: '/incidents',
          method: 'GET',
          headers: headers,
        };
      },
      transformResponse: (
        responseData: IResponseData<{
          contracts: IUserContract[];
          contractsTerminated: boolean;
        }>
      ) => {
        const contracts = responseData.data.contracts;
        const contractsTerminated = responseData.data.contractsTerminated;

        return { contracts, contractsTerminated };
      },
      providesTags: ['Incidents'],
      keepUnusedDataFor: 20,
    }),
    getContractIncidents: builder.query({
      query: (req: IContractIncidentRequest) => {
        const { contractId } = req;
        const headers = getHeaders();

        return {
          url: `/incidents/${contractId}`,
          method: 'GET',
          headers: headers,
        };
      },
      transformResponse: (
        responseData: IResponseData<{
          incidents: IContractIncident[];
          totalIncidents: number;
          isBlocked: boolean;
          hasIncidents: boolean;
          isRegisteredContract: boolean;
          contractStatus: Status;
          centerCoordinates: Coordinates;
          serviceTypeId: string;
          incidentGeoJSON: IGeoJSON;
          dateRange: IDateRange;
        }>
      ) => {
        const incidents = responseData.data.incidents;
        const totalIncidents = responseData.data.totalIncidents;
        const isBlocked = responseData.data.isBlocked;
        const hasIncidents = responseData.data.hasIncidents;
        const isRegisteredContract = responseData.data.isRegisteredContract;
        const contractStatus = responseData.data.contractStatus;
        const centerCoordinates = responseData.data.centerCoordinates;
        const serviceTypeId = responseData.data.serviceTypeId;
        const incidentGeoJSON = responseData.data.incidentGeoJSON;
        const dateRange = responseData.data.dateRange;

        return {
          incidents,
          totalIncidents,
          isBlocked,
          hasIncidents,
          isRegisteredContract,
          contractStatus,
          centerCoordinates,
          serviceTypeId,
          incidentGeoJSON,
          dateRange,
        };
      },

      keepUnusedDataFor: 240,
      providesTags: ['Incidents'],
    }),
    getContractServiceTypeIncidents: builder.query({
      query: (serviceTypeId: string) => {
        const headers = getHeaders();
        return {
          url: `/incidents/service-types/${serviceTypeId}`,
          method: 'GET',
          headers: headers,
        };
      },
      transformResponse: (
        responseData: IResponseData<{
          incidentTypes: IIncidentType[];
        }>
      ) => {
        const incidentTypes = responseData.data.incidentTypes;

        return incidentTypes;
      },
    }),
    getGridIncidents: builder.query({
      query: (requestData: IIncidentRequest) => {
        const { gridId, gridObjectId, contractId } = requestData;

        const headers = getHeaders();

        return {
          url: `/incidents/incident/${gridId}?latestIncident=${gridObjectId}&contractId=${contractId}`,
          method: 'GET',
          headers: headers,
        };
      },
      transformResponse: (
        responseData: IResponseData<{
          incidents: IContractIncident[];
          timeline: ITimelineItem[];
          userAuth: IUserAuth;
          uData: IUData;
          pToken: string;
        }>
      ) => {
        const incidents = responseData.data.incidents;
        const timeline = responseData.data.timeline;
        const userAuth = responseData.data.userAuth;
        const uData = responseData.data.uData;
        const pToken = responseData.data.pToken;

        localStorage.setItem('uData', JSON.stringify(uData));
        localStorage.setItem('pToken', pToken);

        return { incidents, timeline, userAuth };
      },
      providesTags: ['GridIncidents'],
      // keepUnusedDataFor: 5,
    }),
    getIncidentVisitReport: builder.query({
      query: (idData: { contractIncidentId: string; contractId: string }) => {
        const { contractId, contractIncidentId } = idData;
        const headers = getHeaders();

        return {
          url: `/incidents/visit-report/${contractId}/${contractIncidentId}`,
          method: 'GET',
          headers: headers,
        };
      },
      transformResponse: (
        responseData: IResponseData<IVisitReportResponse>
      ) => {
        const {
          data: { visitReport, uData, pToken },
        } = responseData;

        localStorage.setItem('uData', JSON.stringify(uData));
        localStorage.setItem('pToken', pToken);

        return visitReport;
      },
      providesTags: ['IncidentVisitReport'],
    }),

    getObservedIncidentTypes: builder.query({
      query: () => {
        const headers = getHeaders();

        return {
          url: '/incidents//user-defined/types',
          method: 'GET',
          headers: headers,
        };
      },
    }),
    markIncidentVisited: builder.mutation({
      query: (requestData: IVisitReport) => {
        const headers = getHeaders();

        return {
          url: '/incidents/actions/mark-visited',
          method: 'POST',
          headers: headers,
          body: { ...requestData },
        };
      },

      invalidatesTags: ['GridIncidents', 'Incidents'],
    }),
    updateIncidentVisitReport: builder.mutation({
      query: (updateData: {
        contractIncidentId: string;
        contractId: string;
        visitReportId: string;
        observedIncident: string;
        notes: string;
        visitors: IVisitor[];
      }) => {
        const {
          contractId,
          contractIncidentId,
          visitReportId,
          observedIncident,
          notes,
          visitors,
        } = updateData;

        const headers = getHeaders();

        return {
          url: `/incidents/visit-report/${contractId}/${contractIncidentId}?reportId=${visitReportId}`,
          method: 'PATCH',
          body: { observedIncident, notes, visitors },
          headers: headers,
        };
      },
      transformResponse: (
        responseData: IResponseData<{ uData: IUData; pToken: string }>
      ) => {
        const {
          data: { uData, pToken },
        } = responseData;

        localStorage.setItem('uData', JSON.stringify(uData));
        localStorage.setItem('pToken', pToken);

        return responseData;
      },
      invalidatesTags: ['IncidentVisitReport'],
    }),
    updateIncidentAsFalsePositive: builder.mutation({
      query: (updateData: {
        contractId: string;
        contractIncidentId: string;
      }) => {
        const { contractIncidentId, contractId } = updateData;
        const headers = getHeaders();

        return {
          url: `/incidents/${contractId}?contractIncidentId=${contractIncidentId}`,
          method: 'PATCH',
          headers: headers,
        };
      },
      invalidatesTags: ['Incidents'],
    }),
    getReportsOfIncident: builder.query({
      query: (reportsRequest: {
        contractIncidentId: string;
        contractId: string;
        filter: 'all' | 'user';
        withArchived: 'yes' | 'no';
        reportType?: reportType;
      }) => {
        const {
          contractIncidentId,
          contractId,
          filter,
          withArchived,
          reportType,
        } = reportsRequest;
        const headers = getHeaders();

        /**
         * @TODO : At backend, we will need to fetch all reports of incident which has the given contractId, contractIncidentId and userId
         * because, for same incident we may have reports from other users of the contract and we must not show reports of one user to another
         */
        let reportTypeQuery = '';

        if (reportType) {
          reportTypeQuery = `&reportType=${reportType}`;
        }

        return {
          url: `/incidents/reports/${contractId}/${contractIncidentId}?filter=${filter}&withArchived=${withArchived}${reportTypeQuery}`,
          method: 'GET',
          headers,
        };
      },
      transformResponse: (
        responseData: IResponseData<{
          incidentReports: IExistingReport[];
          uData: IUData;
          pToken: string;
        }>
      ) => {
        const incidentReports = responseData.data.incidentReports;
        const uData = responseData.data.uData;
        const pToken = responseData.data.pToken;

        localStorage.setItem('uData', JSON.stringify(uData));
        localStorage.setItem('pToken', pToken);

        return incidentReports;
      },
      providesTags: ['IncidentReports'],
    }),
    sendReportOfIncident: builder.mutation({
      query: (requestData: { report: IIncidentReport }) => {
        const { report } = requestData;
        const headers = getHeaders();

        return {
          url: '/incidents/reports',
          method: 'POST',
          headers,
          body: { report },
        };
      },
      invalidatesTags: [
        'IncidentReports',
        'GridIncidents',
        'send-reports',
        'Incidents',
      ],
    }),
    markIncidentCleaned: builder.mutation({
      query: (requestData: IClean) => {
        const headers = getHeaders();

        return {
          url: '/report/mark-cleaned',
          method: 'POST',
          headers,
          body: {
            requestData,
          },
        };
      },
      invalidatesTags: ['GridIncidents', 'Incidents'],
    }),
    getIncidentCleanedReport: builder.query({
      query: (idData: { contractIncidentId: string; contractId: string }) => {
        const { contractId, contractIncidentId } = idData;
        const headers = getHeaders();

        return {
          url: `/incidents/cleaned-report/${contractId}/${contractIncidentId}`,
          method: 'GET',
          headers,
        };
      },
      transformResponse: (
        responseData: IResponseData<{
          report: ICleanResponse;
          uData: IUData;
          pToken: string;
        }>
      ) => {
        const {
          data: { report, uData, pToken },
        } = responseData;

        localStorage.setItem('uData', JSON.stringify(uData));
        localStorage.setItem('pToken', pToken);

        return report;
      },
      providesTags: ['incident-cleaned-report'],
    }),
    updateIncidentCleanedReport: builder.mutation({
      query: (updateData: ICleanUpdate) => {
        const { contractId, incidentId: contractIncidentId } = updateData;
        const headers = getHeaders();

        return {
          url: `/incidents/cleaned-report/${contractId}/${contractIncidentId}`,
          method: 'PATCH',
          headers,
          body: { updateData },
        };
      },
      invalidatesTags: ['incident-cleaned-report'],
    }),
    registerContractToGISServer: builder.query({
      query: (contractId: string) => {
        const headers = getHeaders();

        return {
          url: `/incidents/gis-registration/${contractId}`,
          method: 'GET',
          headers,
        };
      },
    }),
    getIncidentImage: builder.query({
      query: (imagePath: string) => {
        return {
          url: `http://localhost:8009/image/${imagePath}?road=bridge&reqType="development"`, //for development run image web service locally
          //url: `https://image-web-service.onrender.com/image/${imagePath}?road=bridge`, //for production
          method: 'GET',
        };
      },
    }),
    getSwimmingPoolGridData: builder.query({
      query: (data: {
        gridId: string;
        density: number;
        latitude: number;
        longitude: number;
        contractId: string;
      }) => {
        const { gridId, density, latitude, longitude, contractId } = data;
        const headers = getHeaders();

        return {
          url: `/incidents/swimming-pool/${gridId}/${density}?lat=${latitude}&lng=${longitude}&contractId=${contractId}`,
          method: 'GET',
          headers,
        };
      },
      transformResponse: (
        responseData: IResponseData<ISwimmingPoolGridData>
      ) => {
        return responseData.data;
      },
    }),
  }),
  overrideExisting: true,
});

export const {
  useGetContractsQuery,
  useGetContractIncidentsQuery,
  useGetContractServiceTypeIncidentsQuery,
  useGetGridIncidentsQuery,
  useGetObservedIncidentTypesQuery,
  useGetIncidentVisitReportQuery,
  useGetIncidentCleanedReportQuery,
  useGetReportsOfIncidentQuery,
  useSendReportOfIncidentMutation,
  useMarkIncidentCleanedMutation,
  useMarkIncidentVisitedMutation,
  useUpdateIncidentVisitReportMutation,
  useUpdateIncidentAsFalsePositiveMutation,
  useUpdateIncidentCleanedReportMutation,
  useRegisterContractToGISServerQuery,
  useLazyGetIncidentImageQuery,
  useLazyGetSwimmingPoolGridDataQuery,
} = extendedApiSlice;

export const {
  setCurrentFilterStatus,
  setFromDate,
  setToDate,
  setSelectedIncidentType,
} = incidentSlice.actions;

export const selectAllIncidentValues = (state: RootState) => state.incidents;

export default incidentSlice.reducer;
