interface IProps {
  imagePath: string;
  alt: string;
  width?: string | number;
  height?: string | number;
  className?: string;
  loaderClass?: string;
  title?: string;
  onClick?: () => void;
  loading?: 'lazy' | 'eager';
}

const Image = ({
  imagePath,
  alt,
  width = 394,
  height = 470,
  className,
  loaderClass,
  title,
  onClick,
  loading = 'lazy',
}: IProps) => {
  return (
    <img
      src={`https://poimage.superworld-api.com/perimages/${imagePath}`}
      alt={alt}
      loading={loading}
      className={`${className ? className : ''}`}
      title={title}
      onClick={onClick}
      width={width}
      height={height}
      decoding='async'
    />
  );
};

export default Image;
